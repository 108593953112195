import * as React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

interface Props {
  children: JSX.Element;
}

const AuthLayout = (props: Props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );

  const { children } = props;

  return (
    <div
      id="site-layout-container"
      className="layout-container layout-container-auth"
    >
      <Helmet htmlAttributes={{ lang: "en" }} title={site.siteMetadata.title} />
      <div id="site-content-container">
        <main className="site-main" role="main">
          {children}
        </main>
      </div>
    </div>
  );
};

export default AuthLayout;
