import * as React from "react";

import {
  LoadingContainer,
  navigateTo,
} from "@interactive-investor/onestack-web-shared";

import { withAuth } from "..";
import { AuthContextInjectedProps } from "../interfaces/context";

export interface AuthCallbackProps extends AuthContextInjectedProps {
  location: {
    hash: string;
    search: string;
  };
}

class AuthCallback extends React.Component<AuthCallbackProps> {
  componentDidMount() {
    if (this.props.auth.isAuthenticatedFree()) {
      return navigateTo("/");
    }

    if (/access_token|id_token|error/.test(this.props.location.hash)) {
      return this.props.auth.handleAuthenticationFree();
    }

    if (/code/.test(this.props.location.search)) {
      console.error("Auth code returned when expecting hash");
      return navigateTo("/");
    }

    return;
  }

  render() {
    return (
      <LoadingContainer
        title="Authentication"
        message="Please wait while we handle authentication"
        {...this.props}
      />
    );
  }
}

export default withAuth(AuthCallback);
