import { Router } from "@reach/router";
import React from "react";

import { withAuth } from "../../contexts/Auth";
import AuthCallback from "../../contexts/Auth/components/Callback";
import { AuthContextInjectedProps } from "../../contexts/Auth/interfaces/context";

import AuthLayout from "../../components/authLayout";
import Redirect from "../../components/redirect";
import { Route } from "../../helpers/routing";
import Login from "./login";
import Logout from "./logout";

export interface AuthPageProps extends AuthContextInjectedProps {
  location: any;
}

export interface AuthPageState {
  mounted: boolean;
}

class AuthPage extends React.Component<AuthPageProps, AuthPageState> {
  constructor(props: AuthPageProps) {
    super(props);
    this.state = { mounted: false };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  render() {
    return (
      <AuthLayout>
        <Router>
          <Route Component={AuthCallback} path="/auth/cb/*" {...this.props} />
          <Route Component={Login} path="/auth/login" />
          <Route Component={Logout} path="/auth/logout" />
          <Redirect default={true} to="/" />
        </Router>
      </AuthLayout>
    );
  }
}

export default withAuth(AuthPage);
