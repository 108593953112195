import { navigateTo } from "@interactive-investor/onestack-web-shared";

interface Props {
  to: string;
}

export default (props: Props) => {
  navigateTo(props.to);
  return null;
};
